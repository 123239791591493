body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }


.item-wrapper {
    display: flex;
    height: 20px;
    gap: 5px;
}

.item-image-wrapper {
    width: 30px;
}

.item-wrapper img {
    height: auto;
    max-height: 100%;;
    max-width: 100%;
}

.item-row-wrapper {
    position: relative;
}

.subtext {
    bottom: -17px;
    display: block;
    font-size: 12px;
    position: relative;
    text-decoration: none;
    line-height: 12px;
}

.grand-exchange-subtext {
    bottom: 38px;
    display: block;
    font-size: 12px;
    position: relative;
    text-decoration: none;
}

.nav-link {
    transition: all 0.1s ease-in-out;
    text-underline-offset: 4px;
}

.nav-link:hover {
    text-underline-offset: 8px;
}

.active {
    text-underline-offset: 8px;
}

.skill-ok {
    /* Stolen from health bar */
    color: #088c39;
}

.skill-boost-ok {
    color: orange;
}

.skill-wrapper {
    background-color: #4e4e4a;
    /* border: 1px solid #706350; */
    color: #f0f008;
    font-size: 0.8rem;
    background:
     -webkit-radial-gradient(0 100%, circle, rgba(204,0,0,0) 2px, #4e4e4a 3px),
     -webkit-radial-gradient(100% 100%, circle, rgba(204,0,0,0) 2px, #4e4e4a 3px),
     -webkit-radial-gradient(100% 0, circle, rgba(204,0,0,0) 2px, #4e4e4a 3px),
     -webkit-radial-gradient(0 0, circle, rgba(204,0,0,0) 2px, #4e4e4a 3px);
    background-position: bottom left, bottom right, top right, top left;
    -moz-background-size: 50% 50%;
    -webkit-background-size: 50% 50%;
    background-size: 50% 50%;
    background-repeat: no-repeat;
    padding: 2px 8px;
    text-shadow: 1px 1px 1px #000;

}

.skill-icon {
    max-height: 22px;
    /* width: 24px; */
}

.skill-level-text-wrapper {
    line-height: 24px;
}

.price-chart-min-max {
    line-height: 21px;
}

.field-list-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 8px;
    height: 100%;
    justify-content: center;
}

.field-list-wrapper div {
    line-height: 14px;
}