.item-node {
    border: 1px solid #000;
    border-radius: 3px;
    background-color: #fff;
    font-size: 10px;
    padding: 2px;
}

.item-node .item-wrapper {
    height: 15px;
}

.item-node .item-image-wrapper {
    width: auto;
}

.item-node .item-price {
    position: absolute;
    text-align: right;
    top: 20px;
    white-space: nowrap;
}

.item-node .item-cost {
    position: absolute;
    top: -15px;
    white-space: nowrap;
}